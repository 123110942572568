export function Footer() {
  return (
    <footer className="w-full flex  justify-center text p-8 bg-gray-900   text-sm">
      <div>
        &copy;2023 <strong className={`font-logo `}>Chapose</strong> All rights reserved.
         <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">蜀ICP备2023021488号-1</a>
      </div>
    </footer>
  );
}
