import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { isMobileDevice } from "../utils/mobile";

export const useGo = () => {
  const navigate = useNavigate();

  const go = useCallback(
    (path: string) => {
      if (isMobileDevice()) {
        navigate(path);
      } else {
        window.open(path);
      }
    },
    [navigate]
  );
  return {
    go,
    navigate
  };
};
