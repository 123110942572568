import { Auth0Provider } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import "antd/dist/reset.css";
import "./App.css";
// Include only the reset
import "instantsearch.css/themes/reset.css";
// or include the full Satellite theme
import "instantsearch.css/themes/satellite.css";
import { ConfigProvider } from "antd";
import "moment/locale/zh-cn";
import theme from "./constants/theme";
import history from "./utils/history";
import { getConfig } from "./config";
import "./i18n/config";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./views/Home/index";
import { useDocumentTitle } from "./hooks/useDocumentTitle";
import { useEffect, useCallback } from "react";
import moment from "moment";
import LocalStorageService from "./service/LocalStorageService";

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

function App() {
  const { t, i18n } = useTranslation();
 
  useEffect(() => {
    const lang = LocalStorageService.getItem("lang") || "en";
    i18n.changeLanguage(lang);
    moment.locale(lang);
  }, [i18n]);

  useDocumentTitle(t("title"));

  const onGetStarted = useCallback(() => {
    window.open("https://chapose.com", "_blank");
  }, []);


  return (
    <div id="app" className="App">
      <BrowserRouter>
        <ConfigProvider theme={theme}>
          <Routes>
            <Route index element={<Home onGetStarted={onGetStarted} />} />
          </Routes>
        </ConfigProvider>
      </BrowserRouter>
    </div>
  );
}

export default function Provider() {
  return (
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  );
}
