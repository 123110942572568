import { Button } from "antd";
import "./wave.css";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

interface HomeProps {
  onGetStarted: () => void;
}

const Home = ({ onGetStarted }: HomeProps) => {
  return (
    <div className="w-full h-full bg-black text-white  relative ">
      <Header onGetStarted={onGetStarted} />
      <div className="w-full flex justify-center  font-logo  h-[400px]  relative ">
        <div className="waveWrapper waveAnimation z-0">
          <div className="waveWrapperInner bgTop">
            <div
              className="wave waveTop"
              style={{
                backgroundImage: `url('http://front-end-noobs.com/jecko/img/wave-top.png')`,
              }}
            ></div>
          </div>
          <div className="waveWrapperInner bgMiddle">
            <div
              className="wave waveMiddle"
              style={{
                backgroundImage: `url('http://front-end-noobs.com/jecko/img/wave-mid.png')`,
              }}
            ></div>
          </div>
          <div className="waveWrapperInner bgBottom">
            <div
              className="wave waveBottom"
              style={{
                backgroundImage: `url('http://front-end-noobs.com/jecko/img/wave-bot.png')`,
              }}
            ></div>
          </div>
        </div>
        <div className="sm:w-auto flex justify-center items-center relative   md:w-[1192px] ">
          <div className="flex  flex-col  items-center   ">
            <div className="text-[30px]    font-bold "> Chat, Compose </div>
            <div className="mt-2  text-[20px]"> 对话，创作</div>
            <div className="mt-4">
              <Button
                onClick={onGetStarted}
                type="primary"
                size="large"
                shape="round"
                className="font-logo text-[#07C160] bg-white"
              >
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray-900  text-white   flex   justify-center h-full p-8  relative"></div>
      <div className="w-full flex  justify-center text p-8 bg-gray-800   text-sm">
        <div>
          <strong>Contact</strong>{" "}
          <a href="mailto:contact@chapose.com">contact@chapose.com</a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
