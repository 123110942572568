import { useGo } from "../../../hooks/useGo";
import { useCallback } from "react";

interface HeaderProps {
  onGetStarted?: () => void;
}
export function Header({ onGetStarted }: HeaderProps) {
  const { navigate } = useGo();
  const onGo = useCallback((router:string) =>()=> {  
    navigate(router);
  }, [navigate]);
  
  return (
    <header className="w-full flex bg-gray-900  justify-center p-6">
      <div className="sm:w-auto md:w-[1192px] ">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div  onClick={onGo('/')}  className="text-[24px] text-[#07C160] font-logo font-bold  cursor-pointer">
              Chapose
            </div>
          </div>
          <div className="flex items-center font-bold">
            
            <div onClick={onGetStarted} className="mr-4 cursor-pointer">Sign In</div>
          </div>
        </div>
      </div>
    </header>
  );
}
